import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../styles/Navbar.css'
import Logo from '../images/Arcos-Logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faX} from '@fortawesome/free-solid-svg-icons'



function Navbar() {

  const [buttonState, setButtonState ] = useState(false);

  function handleClick() {
    setButtonState(buttonState => !buttonState)
  } 
  
  let toggleClassCheck = buttonState ? 'active' : null;

  return (
    <nav className="navbar-container">
        <div className="Arcos-Logo-Container">
          <NavLink className="nav-link" to='/'><img src={Logo} alt='Arcos-Logo'></img></NavLink>
        </div>
        <button onClick={handleClick} className={`toggle-button ${toggleClassCheck}`}>
          <FontAwesomeIcon icon={faBars} size='2xl' style={{color: "#efefef",}} />
        </button>
        <button onClick={handleClick} className={`exit-button ${toggleClassCheck}`}>
          <FontAwesomeIcon icon={faX} size='2xl' style={{color: "#efefef",}} />
        </button>
        <div className={`nav-links ${toggleClassCheck}`}>
          <ul>
            <li><NavLink className="nav-link" to='/'>HOME</NavLink></li>
            <li><NavLink className="nav-link" to='/about'> ABOUT</NavLink></li>
            <li><NavLink className="nav-link" to='/services'>SERVICES</NavLink></li>
            <li><NavLink className="nav-link" to='/contact'>CONTACT</NavLink></li>
            <li><NavLink className="nav-link" to='/gallery'>GALLERY</NavLink></li>
          </ul>
        </div>
        <div className='request-estimate-container'>
            <div><NavLink className='nav-link' to='/contact'>Request an Estimate</NavLink></div>
            <div className='header-number'>(773) 459-7517</div>
          </div>
    </nav>

  )
}

export default Navbar