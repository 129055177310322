import React ,{ useEffect} from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ContactForm from './ContactForm'
import '../styles/About.css'
import image5 from '../images/image5.jpeg'
import image7 from '../images/image7.jpg'
import useDocumentTitle from './documentTitle'

function About() {
  useDocumentTitle("About")

  return (
    <div className='page-container'>
      <Navbar/>
      <div className='about-container'>
        <div className='about-us'>
          <div className='about-us-text'>
            <div className='about-us-title'>About Us</div>
            <span>Our mission is to be a provider that brings back the personal touch with our customers. 
            We will build a relationship that will last a life time after you see our quality work. 
            As a locally owned and operated business we feel invested in our clients and constantly strive for customer satisfaction. 
            We won't leave until the job is done right! Call or click to schedule an estimate today!</span>
          </div>
          <img src={image5}></img>
        </div>
        <div className='sobre-nosotros'>
          <div className='sobre-nosotros-text'>
            <div className='sobre-nosotros-title'>Sobre Nosotros</div>
            <span>Nuestra misión es ser un proveedor que devuelva el toque personal con nuestros clientes. 
            Construiremos una relación que durará toda la vida después de ver nuestro trabajo de calidad. 
            Como una empresa de propiedad y operación local nos sentimos comprometidos en nuestros clientes y nos esforzamos constantemente por la satisfacción del cliente. 
            ¡No nos iremos hasta que el trabajo esté bien hecho! ¡Llame o haga clic para programar un presupuesto hoy!</span>
          </div>
          <img src={image7}></img>
        </div>
      <ContactForm/>
      <Footer/>
      </div>
    </div>
  )
}

export default About