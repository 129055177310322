import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ContactForm from './ContactForm'
import '../styles/Contact.css'
import useDocumentTitle from './documentTitle'

function Contact() {
  useDocumentTitle("Contact")
  return (
    <div className='page-contact'>
      <Navbar/>
      <div className='contact-container'>
        <ContactForm/>
      </div>
      <Footer/>
    </div>
  )
}

export default Contact