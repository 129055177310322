import React from 'react'
import contactFormBg from '../images/contact-formBg.jpg'
import '../styles/ContactForm.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'

function ContactForm() {
  return (
    <div className='contact-form-wrapper'>
        <img src={contactFormBg}></img>
        <div className='contact-form-container'>
          <FontAwesomeIcon className='icon' icon={faHandshake} size="2xl" style={{color: "#202020",}} />
          <div className='contact-form-title'>Ready to Get Started?</div>
          <span>We are here to answer your questions. Contact us today at <span style={{color:'#202020'}}>(773) 459-7517</span> or complete the form below.</span>
          <form action="https://formsubmit.co/arcosroofing.co@gmail.com" method="POST">
            <fieldset>
              <ul>
                <li><input type="text" name="name" placeholder='NAME' required></input></li>
                <li><input type="text" name="phone" placeholder='PHONE'></input></li>
                <li><input type="email" name="email" placeholder='EMAIL'></input></li>
                <li><textarea type="text" name="message" placeholder='MESSAGE' required></textarea></li>
              </ul>
            </fieldset>
            <button type='submit'>Submit Form</button>
          </form>
        </div>
    </div>
  )
}

export default ContactForm