import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ContactForm from './ContactForm'
import '../styles/Gallery.css'
import image0 from '../images/image0.jpeg'
import image1 from '../images/image1.jpeg'
import image2 from '../images/image2.jpeg'
import image3 from '../images/image3.jpg'
import image4 from '../images/image4.jpg'
import image5 from '../images/image5.jpeg'
import image6 from '../images/image6.jpeg'
import image7 from '../images/image7.jpg'
import image8 from '../images/image8.jpg'
import image9 from '../images/image9.jpg'
import image10 from '../images/image10.jpg'
import image11 from '../images/image11.jpeg'
import image12 from '../images/image12.jpg'
import image13 from '../images/image13.jpg'
import image14 from '../images/image14.jpeg'
import image15 from '../images/image15.jpg'
import image16 from '../images/image16.jpeg'
import image17 from '../images/image17.jpg'
import image18 from '../images/image18.jpg'
import image19 from '../images/image19.jpg'
import image20 from '../images/image20.jpg'
import image21 from '../images/image21.jpg'
import image22 from '../images/image22.jpg'
import useDocumentTitle from './documentTitle'


function Gallery() {
  useDocumentTitle("Gallery")
  return (
    <div className='page-container'>
      <Navbar/>
      <div className='gallery-container'>
        <div className='gallery-title'>Gallery</div>
        <ul>
          <li className='gallery-image'><img src={image14}></img></li>
          <li className='gallery-image'><img src={image22}></img></li>
          <li className='gallery-image'><img src={image1}></img></li>
          <li className='gallery-image'><img src={image2}></img></li>
          <li className='gallery-image'><img src={image6}></img></li>
          <li className='gallery-image'><img src={image7}></img></li>
          <li className='gallery-image'><img src={image21}></img></li>
          <li className='gallery-image'><img src={image9}></img></li>
          <li className='gallery-image'><img src={image3}></img></li>
          <li className='gallery-image'><img src={image20}></img></li>
          <li className='gallery-image'><img src={image10}></img></li>
          <li className='gallery-image'><img src={image12}></img></li>
          <li className='gallery-image'><img src={image11}></img></li>
          <li className='gallery-image'><img src={image13}></img></li>
          <li className='gallery-image'><img src={image19}></img></li>
          <li className='gallery-image'><img src={image0}></img></li>
          <li className='gallery-image'><img src={image5}></img></li>
          <li className='gallery-image'><img src={image8}></img></li>
          <li className='gallery-image'><img src={image4}></img></li>
          <li className='gallery-image'><img src={image15}></img></li>
          <li className='gallery-image'><img src={image16}></img></li>
          <li className='gallery-image'><img src={image8}></img></li>
          <li className='gallery-image'><img src={image17}></img></li>
          <li className='gallery-image'><img src={image18}></img></li>
        </ul>
      <ContactForm/>
      <Footer/>
      </div>
    </div>
  )
}

export default Gallery