import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ContactForm from './ContactForm'
import { NavLink } from 'react-router-dom'
import '../styles/Services.css'
import image8 from '../images/image8.jpg'
import image10 from '../images/image10.jpg'
import image2 from '../images/image2.jpeg'
import image3 from '../images/image3.jpg'
import image4 from '../images/image4.jpg'
import image11 from '../images/image11.jpeg'
import image12 from '../images/image12.jpg'
import image13 from '../images/image13.jpg'
import image0 from '../images/image0.jpeg'
import useDocumentTitle from './documentTitle'

function Services() {
  useDocumentTitle("Services")
  return (
    <div className='page-container'>
      <Navbar/>
      <div className='services-container'>
        <div className='services-top'>
          <div className='services-titles'>
            <div className='services-title'>
              <span id='blk'>Roofing</span>
              <span id='gry'>Services</span>
            </div>
            <div className='services-button'>
            <NavLink className='nav-link' to='/contact'>Schedule Appointment</NavLink>
            </div>
          </div>
          <div className='services-top-images'>
            <div className='services-image'>
              <img src={image8}></img>
            </div>
            <div className='services-image2'>
              <img src={image10}></img>
            </div>
          </div>
        </div>
        <div className='services-bottom'>
          <div className='services-list-container'>
            <ul>
              <li>
                <div className='service-title'>Roof Installation</div>
                <div className='service-image'><img src={image3}></img></div>
              </li>
              <li>
                <div className='service-title'>Roof Repair</div>
                <div className='service-image'><img src={image4}></img></div>
              </li>
              <li>
                <div className='service-title'>Chimney Install & Repair</div>
                <div className='service-image'><img src={image11}></img></div>
              </li>
              <li>
                <div className='service-title'>Gutters</div>
                <div className='service-image'><img src={image12}></img></div>
              </li>
              <li>
                <div className='service-title'>Waterproofing</div>
                <div className='service-image'><img src={image13}></img></div>
              </li>
              <li>
                <div className='service-title'>Tuckpointing</div>
                <div className='service-image'><img src={image0}></img></div>
              </li>
              <li>
                <div className='service-title'>Skylights</div>
                <div className='service-image'><img src={image2}></img></div>
              </li>
            </ul>
          </div>
        </div>
        <ContactForm/>
        <Footer/>
      </div>
    </div>
  )
}

export default Services