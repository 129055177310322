import React from 'react'
import { NavLink } from 'react-router-dom'
import '../styles/Footer.css'
import AltLogo from '../images/Arcos-Logo-Alt.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from '@fortawesome/free-solid-svg-icons'


function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer-body-container'>
          <div className='footer-content'>
            <div className='footer-left'>
              <img src={AltLogo} alt='Arcos-Alt-Logo'></img>
                <div className='information-container'>
                  <div className='our-location'>
                    <strong>Our Location</strong>
                    <address>Chicago, IL 60629</address>
                  </div>
                  <div className='phone-num-container'>
                    <a className='phone-num'>(773) 459-7517</a>
                  </div>
                </div>
            </div>
              <div className='quick-links'>
                <strong>Quick Links</strong>
                <ul>
                  <li><NavLink className="nav-link" to='/'>Home</NavLink></li>
                  <li><NavLink className="nav-link" to='/about'>About Us</NavLink></li>
                  <li><NavLink className="nav-link" to='/services'>Services</NavLink></li>
                  <li><NavLink className="nav-link" to='/contact'>Contact</NavLink></li>
                  <li><NavLink className="nav-link" to='/gallery'>Gallery</NavLink></li>
                </ul>
              </div>
              <div className='business-hours'>
                <strong>Business Hours:</strong>
                <div>
                  Mon-Fri: 6AM - 10PM
                  <br/>
                  Sat-Sun: 6AM - 6PM
                  <br/>
                  arcosroofing.co@gmail.com
                </div>
              </div>
              <div className='review-container'>
                <div>
                  <a href='https://goo.gl/maps/XKJC8ynbwPqeBd4x9'><span>Leave a Review</span><FontAwesomeIcon icon={faChevronRight} /></a>
                </div>
              </div>
          </div>
        </div>
        <div className='copyright'>
          <span>© 2023 All Rights Reserved. Arcos Roofing.</span>
        </div>
    </div>
  )
}

export default Footer