import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ContactForm from './ContactForm'
import { NavLink } from 'react-router-dom'
import '../styles/Home.css'
import arcosPhoto from '../images/arcosPhoto-0.jpg'
import galleryBg from '../images/gallery-background.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faAward, faLock } from '@fortawesome/free-solid-svg-icons'
import useDocumentTitle from './documentTitle'

function Home() {
  useDocumentTitle("Home")
  return (
    <div className='page-container'>
      <Navbar/>
      <div className='home-container'>
        <section className='home-phone-number'>
          <a href='tel:773-459-7517'>(773) 459-7517</a>
        </section>
        <section className='home-main-mobile'>
          <div className='home-main-mobile-wrapper'>
            <img src={arcosPhoto}></img>
            <div className='home-main-mobile-quote'>
              <span>Family Owned and Operated</span>
              <span>Serving Homes in Chicago, IL</span>  
            </div>
            <div className='home-main-mobile-contact-wrapper'>
              <div className='home-main-mobile-contact-quote'>
                We are a small, local business committed to raising the bar for roofing services in the Chicagoland area.
              </div>
              <a href='/Contact'>Let's Get to Work</a>
            </div>
          </div>
        </section>
        <section className='home-main'>
          <div className='home-main-left'>
            <div className='home-main-left-quote'>
              <span id='gray'>Family Owned and Operated</span>
              <span>Serving Homes in Chicago, IL </span>
            </div>
            <div className='home-main-left-contact-quote-container'>
              <div className='home-main-left-contact-container'>
                <div className='home-main-left-contact-quote'>
                  We are a small, local business committed to raising the bar for roofing services in the Chicagoland area.
                </div>
                <NavLink className='nav-link' to='/contact'>Let's Get to Work</NavLink>
              </div>
            </div>
          </div>
          <div className='home-main-right'>
            <img src={arcosPhoto}></img>
          </div>
        </section>
        <section className='home-main-gallery'>
          <div className='home-main-gallery-wrapper'>
            <img src={galleryBg} alt='gallery-background'></img>
            <div className='home-gallery-icon'>
              <FontAwesomeIcon icon={faLayerGroup} size="2xl" style={{color: "#ffffff",}} />
            </div>
            <div className='home-gallery-title'>
              <span>Our Photo Gallery</span>
            </div>
            <div className='home-gallery-photos'>
              <ul>
                <li><img src={require('../images/image2.jpeg')}></img></li>
                <li><img src={require('../images/image1.jpeg')}></img></li>
                <li><img src={require('../images/image5.jpeg')}></img></li>
                <li><img src={require('../images/image6.jpeg')}></img></li>
              </ul>
            </div>
            <div className='home-gallery-button'>
            <NavLink className='nav-link' to='/gallery'>Arcos Photo Gallery</NavLink>
            </div>
          </div>
        </section>
        <section className='home-main-services'>
          <div className='home-main-services-wrapper'>
            <div className='home-main-services-left'>
              <div className='home-main-services-title'>Our Services</div>
              <div>
                <ul>
                  <li>Roof Installation</li>
                  <li>Roof Repair</li>
                  <li>Chimney Install & Repair</li>
                  <li>Gutters</li>
                  <li>Waterproofing</li>
                  <li>Tuckpointing</li>
                  <li>Skylights</li>
                </ul>
              </div>
            </div>
            <div className='home-main-services-right'>
              <div className='experience'>
                <FontAwesomeIcon className='icon'icon={faAward} size="2xl" style={{color: "#000000",}}/>
                <div className='experience-title'>20 Years Experience</div>
                <span>Trust our experience to get the job done! We have over 20 years of experience in the industry.</span>
              </div>
              <div className='insured'>
                <FontAwesomeIcon className='icon' icon={faLock} size="2xl" style={{color: "#000000",}} />
                <div className='insured-title'>Licensed and Insured</div>
                <span>We are 100% licensed and insured! We value protecting your safety and ours.</span>
              </div>
            </div>
          </div>
        </section>
        <ContactForm/>
        <Footer/>
      </div>
    </div>
  )
}

export default Home